<template>
  <div>
    <top-bar-navigation v-if="useDesktop"></top-bar-navigation>
    <top-bar-navigation-mobile v-else></top-bar-navigation-mobile>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'top-navigation-proxy',
  components: {
    TopBarNavigation: () => import(/* webpackPrefetch: true */ '@/views/elements/top-bar-navigation.vue'),
    TopBarNavigationMobile: () => import(/* webpackPrefetch: true */ '@/views/elements/top-bar-navigation2.vue'),
  },
  data() {
    return {
      useDesktop: false,
    };
  },
  mounted() {
    this.handleResize();
    document.addEventListener('resize', this.handleResize, true);
  },
  beforeDestroy() {
    document.removeEventListener('resize', this.handleResize);
  },
  computed: {},
  methods: {
    handleResize() {
      this.useDesktop = document.body.clientWidth >= 768;
    },
  },
})
export default class TopNavigationProxy extends Vue {
}
</script>

<style scoped>

</style>
